import React, { FC } from "react";

export type HiddenBreadcrumbProps = {
  data?: string;
};

const HiddenBreadcrumb: FC<HiddenBreadcrumbProps> = ({ data = "" }: HiddenBreadcrumbProps): JSX.Element | null => {
  if (data !== "") {
    const dsaCategoryId = `DSACategoryId${data}/`;
    return (
      <div id="dsa-category-id" className="visually-hidden" data-drroot="hidden-breadcrumb">
        <div>{dsaCategoryId}</div>
      </div>
    );
  }

  return <div data-drroot="hidden-breadcrumb"></div>;
};

export default HiddenBreadcrumb;
