import React, { FC, useEffect, useRef } from "react";
import BreadcrumbItem from "../breadcrumbs/breadcrumbs-item";

export type BreadcrumbsProps = {
  breadcrumbs?: { path: string; name: string }[];
};

const BreadcrumbsWithoutGender: FC<BreadcrumbsProps> = ({ breadcrumbs = [] }: BreadcrumbsProps): JSX.Element | null => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      (scrollRef.current as any).scrollLeft = (scrollRef.current as any).offsetWidth;
    }
  }, [scrollRef]);

  if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
    return (
      <div id="product-detail-new-breadcrumb-without-gender" data-drroot="breadcrumb-without-gender">
        <div className="shadow"></div>
        <div className="breadcrumb-wrapper">
          <ul className="breadcrumb-list" ref={scrollRef as any}>
            {breadcrumbs.map((breadcrumb: { path: string; name: string }) => (
              <BreadcrumbItem
                key={`productDetailBreadcrumb#${breadcrumb.path}`}
                data-testid={`productDetailBreadcrumb#${breadcrumb.path}`}
                link={breadcrumb?.path?.startsWith("/") ? breadcrumb?.path : `/${breadcrumb?.path}`}
                text={breadcrumb.name}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return <div data-drroot="breadcrumb-without-gender"></div>;
};

export default BreadcrumbsWithoutGender;
