/* istanbul ignore file */
import ReactDOM from "react-dom";
import * as React from "react";
import {
  FRAGMENT_NAME,
  FRAGMENT_NAME_BREADCRUMB_WITHOUT_GENDER,
  FRAGMENT_NAME_HIDDEN_BREADCRUMB,
  FRAGMENT_NAME_SEO_TEXT,
  PARTIAL_NAME_BREADCRUMB_WITHOUT_GENDER,
  PARTIAL_NAME_HIDDEN_BREADCRUMB,
  PARTIAL_NAME_SEO_TEXT,
} from "../constant";
import HiddenBreadcrumb from "./components/hidden-breadcrumb/hidden-breadcrumb";
import BreadcrumbsWithoutGender from "./components/breadcrumbs-without-gender/breadcrumbs-without-gender";
import SeoText from "./components/seotext/seotext";
import "./styles/style.scss";

if (window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <SeoText {...window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]} />,
    document.getElementById(FRAGMENT_NAME)
  );
}

if (window[`__${FRAGMENT_NAME_SEO_TEXT}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <SeoText {...window[`__${FRAGMENT_NAME_SEO_TEXT}__PROPS` as keyof Window]} />,
    document.querySelector(`#${FRAGMENT_NAME}[fragment-partial="${PARTIAL_NAME_SEO_TEXT}"]`)
  );
}

if (window[`__${FRAGMENT_NAME_BREADCRUMB_WITHOUT_GENDER}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <BreadcrumbsWithoutGender {...window[`__${FRAGMENT_NAME_BREADCRUMB_WITHOUT_GENDER}__PROPS` as keyof Window]} />,
    document.querySelector(`#${FRAGMENT_NAME}[fragment-partial="${PARTIAL_NAME_BREADCRUMB_WITHOUT_GENDER}"]`)
  );
}

if (window[`__${FRAGMENT_NAME_HIDDEN_BREADCRUMB}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <HiddenBreadcrumb {...window[`__${FRAGMENT_NAME_HIDDEN_BREADCRUMB}__PROPS` as keyof Window]} />,
    document.querySelector(`#${FRAGMENT_NAME}[fragment-partial="${PARTIAL_NAME_HIDDEN_BREADCRUMB}"]`)
  );
}

if ((window as any).dispatchDREvent) {
  (window as any).dispatchDREvent({ key: "product-detail-seo-new", type: "done" });
}
