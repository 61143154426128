/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
export function delphoiEvent(eventName: string, data: any) {
  const { _gcol } = window as any;
  if (!_gcol) {
    return;
  }

  _gcol.channel = _gcol.channel || "mobileweb";
  _gcol.Fire(eventName, data);
}
