import React, { FC, useCallback, useState } from "react";
import type { SeoContent } from "../../server";
import { delphoiEvent } from "../../../../../../helpers/delphoiEvent";

type SeoInfoProp = {
  content?: SeoContent;
  showMore: string;
  showLess: string;
  isDynamicRendering: boolean;
};
type ShowStyleType = { style: { maxHeight: string } } | {};

const eventInfos = {
  PDP_SHOWMORE_DETAIL: "pdp_showmore_detail",
  CHANNELNAME: "mobileweb",
  EVENTTYPE: "product_click",
};

const SeoText: FC<SeoInfoProp> = ({ content, showMore, showLess, isDynamicRendering }: SeoInfoProp) => {
  const [isShow, setShow] = useState(isDynamicRendering);
  const showStyle: ShowStyleType = isShow ? {} : { style: { maxHeight: "87px" } };
  const ExpandButtonClassName = "show-more" + (isShow ? "" : " with-before");
  const expandableBoxClassName = "seo-content-text-body expandable" + (isShow ? "" : " hide-image");

  const throwPdpShowDetailedInfoEvent = useCallback(() => {
    const { _gcol, localStorage } = window as any;
    // eslint-disable-next-line @typescript-eslint/camelcase
    const userInfo = localStorage.getItem("userDataLayerInfo") || JSON.stringify({ user_memberstatus: 0 });
    if (_gcol) {
      const { user_customerid: customerId, user_memberstatus: memberStatus } = JSON.parse(userInfo);
      const fullUrl = window.location.href || "";
      const data = {
        event: eventInfos.PDP_SHOWMORE_DETAIL,
        channel: eventInfos.CHANNELNAME,
        pid: _gcol.GetPid().pid || "",
        sid: _gcol.GetSid().sid || "",
        tv003: eventInfos.EVENTTYPE,
        tv007: customerId || "",
        tv018: memberStatus.toString(),
        tv019: fullUrl,
        tv040: window.location.pathname.split("-p-")[1],
      };
      delphoiEvent(eventInfos.PDP_SHOWMORE_DETAIL, data);
    }
  }, []);

  const onShowMoreToggled = () => {
    setShow(!isShow);
    throwPdpShowDetailedInfoEvent();
  };

  if (content !== null && content !== undefined && content.text !== "") {
    // isMounted is necessary for dangerouslySetInnerHTML, when we do server side rendering, it's working before mount
    return (
      <div id="seo-content-text-context" data-drroot="seo-content">
        {content.title && (
          <div className="seo-content-text-header">
            <h2 data-testid="seo-title">{content.title}</h2>
          </div>
        )}
        {content.text && (
          <>
            <div className={expandableBoxClassName} data-testid="seo-text" {...showStyle}>
              <span className="seo-html" dangerouslySetInnerHTML={{ __html: content.text }} />
            </div>
            <button data-testid="expand-button" className={ExpandButtonClassName} onClick={onShowMoreToggled}>
              {isShow ? showLess : showMore}
            </button>
          </>
        )}
      </div>
    );
  }
  return <div data-drroot="seo-content"></div>;
};

export default SeoText;
