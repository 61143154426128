import React, { FC } from "react";

export type BreadcrumbItemProps = {
  text?: string;
  link?: string;
};

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ link = "/", text = "" }: BreadcrumbItemProps): JSX.Element => {
  const onItemClick = () => {
    if ((window as any).GTMService) {
      (window as any).GTMService.event("GAEvent", {
        eventCategory: "Product Detail",
        eventAction: "Breadcrumb",
        eventLabel: "Click",
      });
    }
    return true;
  };
  return (
    <li className="product-detail-new-breadcrumbs-item">
      <a href={link} data-testid={`breadcrumb-item-${link}`} onClick={onItemClick}>
        {text}
      </a>
      <i className="icon-arrow seperator" />
    </li>
  );
};

export default BreadcrumbItem;
